
























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddQuotationToBasketRequest,
    QuotationDetailsLineItemViewObject,
    BasketViewModel,
    LineItemViewObject
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import { BasketGetter } from '@/store/basket';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class ProjectOrderQuotationDetailsOrderLine extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @AppGetter hideNetPrices!: boolean;

    @Prop({
        required: true,
        type: String
    }) quotationId!: string;

    @Prop({
        required: true,
        type: Object
    }) orderLine!: QuotationDetailsLineItemViewObject;

    @Prop({
        required: true,
        type: Boolean
    }) addToAllPending!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) addedToBasketFromParent!: boolean;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    showErrorMsg: boolean = false;
    isAlreadyInBasket: boolean = false;
    timeout: number | null = null;
    addToBasketQuantity: number = this.orderLine.maxQuantity;

    get disableAddToBasket(): boolean {
        return this.showErrorMsg || this.isAlreadyInBasket || this.addToAllPending || this.addedToBasketFromParent || this.orderLine.maxQuantity === 0 || !this.orderLine.allowOrdering;
    }

    get findOrderLineInBasket(): LineItemViewObject | undefined {
        return this.basket.lineItems.find(lineItem => lineItem.referenceDocument !== undefined ? lineItem.referenceDocument!.documentItem === this.orderLine.lineId && lineItem.referenceDocument!.documentId === this.quotationId : false);
    }

    mounted() {
        this.setAddToBasketQuantityToMax();
        this.alreadyInBasket();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addOrderLineToBasket() {
        this.pending = true;

        const payload: AddQuotationToBasketRequest = {
            quotationId: this.quotationId,
            lines: [
                {
                    lineId: this.orderLine.lineId,
                    quantity: this.addToBasketQuantity
                }
            ]
        };

        try {
            await Api.basket.addQuotationToBasket(payload);
            this.showSuccessIndicator();
            this.setAddToBasketQuantityToMax();
        } finally {
            this.pending = false;
            this.alreadyInBasket();
        }
    }

    setAddToBasketQuantityToMax() {
        const orderLineInBasket = this.findOrderLineInBasket;
        // first subtract maxQuantity, with already added to basket quantity so the addToBasketQuantity never exceeds the max quantity the user can get.
        if (orderLineInBasket && orderLineInBasket.quantity > 0) {
            this.addToBasketQuantity = this.orderLine.maxQuantity - orderLineInBasket.quantity;
        } else {
            this.addToBasketQuantity = this.orderLine.maxQuantity;
        }
    }

    alreadyInBasket(): void {
        let orderLineInBasket = this.findOrderLineInBasket;

        if (orderLineInBasket) {
            // If the basket has reached the maximum quantity allowed the button is disabled
            if (orderLineInBasket.quantity === this.orderLine.maxQuantity) {
                this.isAlreadyInBasket = true;
                // We will not display an error message in this scenario, as it will occur at page load if all items are already in the basket.
            } else if (orderLineInBasket.quantity + this.addToBasketQuantity > this.orderLine.maxQuantity) {
                this.isAlreadyInBasket = true;
                this.showErrorMsg = true;
            } else {
                this.isAlreadyInBasket = false;
                this.showErrorMsg = false;
            }
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    validateQuantity() {
        if (this.addToBasketQuantity < 0) {
            this.addToBasketQuantity = 0;
        }

        // If the input field is set to 0, the button should be disabled.
        if (this.addToBasketQuantity === 0) {
            this.isAlreadyInBasket = true;
            this.showErrorMsg = false;
        } else {
            // Reset the value to "false" to prevent the display of previous results.
            this.isAlreadyInBasket = false;

            // If the input quantity exceeds the maximum quantity on the order line, display an error message.
            this.showErrorMsg = this.addToBasketQuantity > this.orderLine.maxQuantity;
            this.alreadyInBasket();
        }
    }
}
